<template>
  <div class="floatingBox">
    <SignupUI>
      <template v-slot:stepper><signup-stepper /></template>
    </SignupUI>
  </div>
</template>

<script>
import SignupUI from "@/components/Signup/SignupUI";
import SignupStepper from "@/components/Signup/SignupStepper";
import Register from "@/layouts/Register";

export default {
  name: "Signup",
  components: {
    SignupUI,
    SignupStepper
  },
  created() {
    this.$emit(`update:layout`, Register);
  }
};
</script>

<template>
  <div class="ys-verification">
    <h1>{{ prefix }}</h1>
    <svg width="16" height="1">
      <rect width="16" height="1" />
    </svg>
    <div class="input-wrapper" v-for="item in [1, 2, 3]" :key="item">
      <input
        @paste="handlePaste"
        title="code"
        v-focus="item - 1 === currentIndex"
        maxlength="1"
        inputmode="numeric"
        pattern="[0-9]*"
        @input="handleInput($event, item - 1)"
        @keyup.delete="onDelete()"
        v-model.number="code[item - 1]"
        :data-cy="`verificationCode-${item}`"
      />
    </div>
    <svg width="16" height="1">
      <rect width="16" height="1" />
    </svg>
    <div class="input-wrapper" v-for="item in [4, 5, 6]" :key="item">
      <input
        @paste="handlePaste"
        title="code"
        v-focus="item - 1 === currentIndex"
        maxlength="1"
        inputmode="numeric"
        pattern="[0-9]*"
        @input="handleInput($event, item - 1)"
        @keyup.delete="onDelete()"
        v-model.number="code[item - 1]"
        :data-cy="`verificationCode-${item}`"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "VerificationCodeInput",
  props: {
    prefix: {
      type: String,
      default: "M"
    }
  },
  directives: {
    focus: {
      componentUpdated: function (el, obj) {
        obj.value && el.focus();
      }
    }
  },
  created() {
    this.code = new Array(6).fill("");
  },
  methods: {
    handleInput(e, index) {
      if (e.inputType !== "insertFromPaste") {
        this.currentIndex = index;
        e.target.value = this.validateNumber(e.target.value);

        if (e.target.value !== "") {
          this.currentIndex = index + 1;
        }
      }
      !this.code.includes("") && this.onComplete();
    },
    onDelete() {
      this.code = new Array(6).fill("");
      this.currentIndex = 0;
    },
    onComplete() {
      this.$emit("onCompleted", this.code.join(""));
    },
    validateNumber(val) {
      let newVal;

      if (val.length > 1) {
        newVal = val.substr(0, 1);
      } else {
        newVal = val;
      }
      return newVal.replace(/\D/g, "");
    },
    handlePaste(e) {
      const textInput = e.clipboardData.getData("text");
      let isnum = /^\d+$/.test(textInput);
      let textInputArray = textInput.split("");
      if (textInputArray.length > 6 || !isnum) {
        e.preventDefault();
      } else {
        this.code = textInputArray.map((e) => parseInt(e));
        this.currentIndex = 5;
      }
    }
  },
  data() {
    return {
      code: [],
      currentIndex: 0
    };
  }
};
</script>

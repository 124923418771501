<template>
  <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%">
    <form>
      <MessageBox
        v-model="messageBox.state"
        :message-content="messageBox.content"
        :message-class="messageBox.cssClass"
        :destination="messageBox.destination"
      />
      <h1>{{ this.$t("signPasswordHeading") }}</h1>
      <p v-html="$t('signPasswordText')"></p>
      <v-text-field
        outlined=""
        v-model="password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min, rules.containsUppercase, rules.containsLowercase, rules.containsNumber]"
        :type="show1 ? 'text' : 'password'"
        name="input-8-2"
        :label="$t('signPasswordBox')"
        value=""
        class="input-group--focused"
        @click:append="show1 = !show1"
        data-cy="password"
      ></v-text-field>

      <v-text-field
        outlined=""
        v-model="rePassword"
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, pwMatch]"
        :type="show2 ? 'text' : 'password'"
        name="input-8-2"
        :label="$t('signPasswordConfirm')"
        value=""
        class="input-group--focused"
        @click:append="show2 = !show2"
        data-cy="rePassword"
      ></v-text-field>
    </form>
    <div class="buttonGroup">
      <v-btn rounded @click="back" data-cy="back" x-large depressed class="mr-2"><p v-html="$t('signBack')"></p></v-btn>
      <v-btn rounded color="primary" data-cy="submit" @click="submit" x-large depressed
        ><p v-html="$t('signPasswordNext')"></p
      ></v-btn>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { signupUser } from "@/services/authService";
import MessageBox from "@/components/Common/MessageBox";
import config from "@/config.js";

export default {
  components: { MessageBox },
  mixins: [validationMixin],
  validations: {
    password: {
      required,
      minLength: minLength(8),
      containsUppercase: function (value) {
        return /[A-Z]/.test(value);
      },
      containsLowercase: function (value) {
        return /[a-z]/.test(value);
      },
      containsNumber: function (value) {
        return /[0-9]/.test(value);
      }
    },
    rePassword: {
      required: required,
      sameAs: sameAs("password")
    }
  },
  data: () => ({
    show1: false,
    show2: false,
    password: "",
    rePassword: "",
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    }
  }),

  computed: {
    pwMatch() {
      return this.password === this.rePassword || this.$t("errorMsg.signup.passwordNotMatch");
    },
    pwErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.name.required && errors.push(this.$t("errorMsg.signup.passwordEmpty"));
      !this.$v.name.containsUppercase && errors.push(this.$t("errorMsg.signup.passwordFormat"));
      !this.$v.name.containsLowercase && errors.push(this.$t("errorMsg.signup.passwordFormat"));
      !this.$v.name.containsNumber && errors.push(this.$t("errorMsg.signup.passwordFormat"));

      return errors;
    },

    rules() {
      return {
        required: (value) => !!value || this.$t("errorMsg.signup.passwordEmpty"),
        min: (v) => v.length >= 8 || this.$t("errorMsg.signup.passwordFormat"),
        containsUppercase: (v) => /[A-Z]/.test(v) || this.$t("errorMsg.signup.passwordFormat"),
        containsLowercase: (v) => /[a-z]/.test(v) || this.$t("errorMsg.signup.passwordFormat"),
        containsNumber: (v) => /[0-9]/.test(v) || this.$t("errorMsg.signup.passwordFormat")
      };
    },
    language() {
      switch (this.$i18n.locale) {
        case "en":
          return 0;
        case "zhHant":
          return 1;
        case "zhHans":
          return 2;
        default:
          return 0;
      }
    }
  },

  methods: {
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          password: this.password
        };
        this.$store.commit("editSignupForm", payload);

        const signupForm = this.$store.state.signupForm;

        const result = await signupUser(
          this.$axios,
          config,
          signupForm.userid,
          signupForm.email.toLowerCase(),
          signupForm.emailVerificationCode,
          signupForm.tel,
          signupForm.phoneVerificationCode,
          signupForm.password,
          this.language
        );

        if (result.status === 200) {
          this.$emit("complete");
        } else if (result.status === 500) {
          this.messageBox.content = this.$t("dialogMsg.signUp.userid");
          this.messageBox.state = true;
          this.messageBox.destination = "";
          this.$store.commit("showProgressLine", false);
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        }
      }
    },
    back() {
      this.$emit("back");
    }
  }
};
</script>

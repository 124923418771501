<template>
  <div class="container">
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <h1>{{ this.$t("signHeading") }}</h1>
    <form @submit="submit" onSubmit="return false;">
      <v-text-field
        outlined=""
        v-model="userid"
        :messages="useridMessages"
        :error-messages="useridErrors"
        :label="usernameLabel"
        required
        @input="useridCheckReset"
        @blur="checkUsername"
        :loading="useridCheck === 'checking'"
        data-cy="userid"
      ></v-text-field>
      <v-checkbox
        class="ma-0"
        inset
        v-model="checkbox"
        required
        :error-messages="checkboxErrors"
        @change="$v.checkbox.$touch()"
        @blur="$v.checkbox.$touch()"
      >
        <template v-slot:label>
          <div class="pl-2">
            {{ $t("signAgreement") }}
          </div>
        </template>
      </v-checkbox>
      <!-- #Await debug in backend -->
      <!-- <v-checkbox class="ma-0" inset v-model="checkboxMarketing">
        <template v-slot:label>
          <div class="pl-2">
            {{ $t("signMarketing") }}
          </div>
        </template>
      </v-checkbox> -->
    </form>
    <div class="buttonGroup">
      <v-btn
        :loading="this.useridCheck === 'checking'"
        rounded
        color="primary"
        x-large=""
        @click="submit"
        depressed
        data-cy="next"
        ><span style="font-weight: 600">{{ $t("signNext") }} </span></v-btn
      >
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength, helpers } from "vuelidate/lib/validators";
import MessageBox from "@/components/Common/MessageBox";
import { checkUserUnique } from "@/services/authService";
import config from "@/config.js";

const alphaNumAndUnderscoreValidator = helpers.regex("alphaNumAndUnderscoreValidator", /^[a-z\d_]*$/i);

export default {
  props: ["signupForm"],
  components: {
    MessageBox
  },
  data: () => ({
    userid: "",
    tel: "",
    checkbox: false,
    checkboxMarketing: false,
    useridCheck: "unchecked",
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    }
  }),
  mixins: [validationMixin],
  validations: {
    userid: {
      required,
      maxLength: maxLength(20),
      minLength: minLength(4),
      alphaNumAndUnderscoreValidator
    },
    checkbox: {
      required,
      checked(val) {
        return val;
      }
    }
  },
  computed: {
    checkboxErrors() {
      const errors = [];
      if (!this.$v.checkbox.$dirty) return errors;
      !this.$v.checkbox.checked && errors.push(this.$t("errorMsg.signup.termsNotChecked"));
      return errors;
    },
    useridMessages() {
      const messages = [];
      // this.useridCheck === "checking" &&
      //   messages.push("Checking userid availability");
      // this.useridCheck === "unique" && messages.push("Username available");
      return messages;
    },
    useridErrors() {
      const errors = [];
      if (!this.$v.userid.$dirty) return errors;
      !this.$v.userid.maxLength && errors.push(this.$t("errorMsg.signup.usernameFormatWrong"));
      !this.$v.userid.minLength && errors.push(this.$t("errorMsg.signup.usernameFormatWrong"));
      !this.$v.userid.alphaNumAndUnderscoreValidator && errors.push(this.$t("errorMsg.signup.usernameFormatWrong"));
      !this.$v.userid.required && errors.push(this.$t("errorMsg.signup.usernameEmpty"));
      this.useridCheck === "taken" && errors.push(this.$t("errorMsg.signup.usernameExists"));
      return errors;
    },
    allowSubmit() {
      return !this.$v.$invalid && this.useridCheck === "unique";
    },
    usernameLabel() {
      return this.$t("signUser");
    }
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.useridCheck !== "unique") {
        await this.checkUsername();
      }
      if (this.allowSubmit) {
        const payload = {
          userid: this.userid,
          checkbox: this.checkbox,
          roleInfo: {
            marketingConsent: this.checkboxMarketing
          }
        };
        this.$store.commit("editSignupForm", payload);
        this.$emit("complete");
      }
    },
    async checkUsername() {
      this.$v.userid.$touch();
      if (!this.$v.userid.$invalid) {
        this.useridCheck = "checking";
        const result = await checkUserUnique(this.$axios, config, this.userid);

        if (result.status === 200) {
          if (result.data.unique) {
            this.useridCheck = "unique";
          } else {
            this.useridCheck = "taken";
          }
        } else if (result.status === 500) {
          this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
          this.messageBox.state = true;
          this.messageBox.destination = "";
          this.$store.commit("showProgressLine", false);
        } else {
          this.messageBox.state = true;
          this.messageBox.content = "Username check failed";
          this.useridCheck = "unchecked";
        }
      }
    },
    useridCheckReset() {
      this.userid = this.userid.toLowerCase();
      this.useridCheck = "unchecked";
    }
  },
  mounted() {
    this.userid = this.$store.state.signupForm.userid;
    this.email = this.$store.state.signupForm.email;
  }
};
</script>

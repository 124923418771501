<template>
  <div>
    <h1>{{ $t("signWaitHeading") }}</h1>
    <p v-html="$t('signWaitText')"></p>
    <v-progress-linear :value="progress" height="25" rounded>
      <template v-slot:default="{ value }">
        <strong style="color: white">{{ Math.ceil(value) }}%</strong>
      </template>
    </v-progress-linear>
  </div>
</template>

<script>
const duration = 30;
export default {
  data: () => ({
    countDown: duration
  }),
  mounted() {
    this.countDownTimer();
  },
  computed: {
    progress() {
      return ((duration - this.countDown) * 100) / duration;
    }
  },
  methods: {
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
      if (this.countDown <= 0) {
        this.$emit("complete");
      }
    }
  }
};
</script>

<template>
  <div class="container">
    <div class="formGroup">
      <h1>{{ this.$t("signEmailHeading") }}</h1>
      <v-text-field
        outlined=""
        v-model="email"
        :error-messages="emailErrors"
        :label="$t('signEmailBox')"
        required
        @input="emailInputEvent"
        @blur="$v.email.$touch()"
        @keydown.enter="handleEnter"
        data-cy="email"
        v-if="!codeSent"
      ></v-text-field>
      <div v-if="!codeSent && countDown > 0">
        <p>
          {{ this.$t("signWait", { countDown: this.countDown }) }}
        </p>
      </div>
      <div v-if="codeSent && !codeCorrect">
        <p>
          {{ $t("signEmailVerifyText", { email: this.email }) }}
        </p>
        <sms-code @onCompleted="codeCompleted" @keydown.enter="submit" prefix="E"></sms-code>
        <p v-if="countDown > 0">
          {{ $t("signWait", { countDown: this.countDown }) }}
        </p>
        <p v-else>
          <a @click="sendEmailCode"> {{ this.$t("signResend") }} </a>
        </p>
      </div>
      <div v-if="codeCorrect">
        <v-alert type="success" color="secondary" outlined>{{ this.$t("signVerified") }}</v-alert>
      </div>
      <MessageBox
        v-model="messageBox.state"
        :message-content="messageBox.content"
        :message-class="messageBox.cssClass"
        :destination="messageBox.destination"
      />
    </div>

    <div class="buttonGroup">
      <v-btn rounded @click="back" x-large depressed class="mr-2" data-cy="back"
        ><p>{{ this.$t("signBack") }}</p></v-btn
      >
      <v-btn
        depressed
        rounded
        x-large
        color="primary"
        @click="triggerSendCode"
        v-if="!codeSent"
        :key="codeSent"
        :disabled="!allowSendCode"
        :loading="loading"
        data-cy="send"
        ><p>{{ this.$t("signSend") }}</p></v-btn
      >
      <v-btn
        rounded
        color="primary"
        @click="submit"
        :disabled="!allowSubmit"
        x-large
        depressed
        v-else
        :key="allowSubmit"
        :loading="loading"
        data-cy="next"
        ><p>{{ this.$t("signNext") }}</p></v-btn
      >
    </div>
  </div>
</template>

<script>
import smsCode from "./smsCode";
import { sendVerifCodeToEmail, checkEmailVerificationCode } from "@/services/authService";
import config from "@/config.js";
import MessageBox from "@/components/Common/MessageBox";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  data: () => ({
    code: "",
    countDown: 0,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    email: "",
    codeSent: false,
    loading: false
  }),
  components: {
    smsCode,
    MessageBox
  },
  mixins: [validationMixin],
  validations: {
    email: { required, email }
  },
  mounted() {
    if (this.$store.state.signupForm.email !== "") {
      this.codeSent = true;
    }
    this.email = this.$store.state.signupForm.email;
  },
  methods: {
    async submit() {
      if (this.codeCorrect) {
        this.$emit("complete");
      } else {
        this.loading = true;
        const verificationResult = await this.checkVerificationCode();

        if (verificationResult) {
          const payload = {
            email: this.email,
            emailVerificationCode: this.code,
            emailVerifyCorrect: true
          };
          this.$store.commit("editSignupForm", payload);
          this.$emit("complete");
        } else {
          this.messageBox.state = true;
        }
        this.loading = false;
      }
    },
    back() {
      if (this.codeSent) {
        this.codeSent = !this.codeSent;
      } else {
        this.$emit("back");
      }
    },
    triggerSendCode() {
      this.$v.$touch();
      if (!this.$v.$invalid && this.countDown === 0) {
        this.sendEmailCode();
      }
    },
    emailInputEvent() {
      this.codeSent = false;
      if (this.codeCorrect) {
        const payload = {
          emailVerifyCorrect: false,
          emailVerificationCode: ""
        };
        this.$store.commit("editSignupForm", payload);
      }
      this.code = "";
    },
    codeCompleted(code) {
      this.code = code;
      this.submit();
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    async sendEmailCode() {
      let language;
      switch (this.$i18n.locale) {
        case "en":
          language = 0;
          break;
        case "zhHant":
          language = 1;
          break;
        case "zhHans":
          language = 2;
          break;
        default:
          language = 0;
      }
      const params = {
        userid: this.$store.state.signupForm.userid,
        language: language
      };
      this.loading = true;
      const result = await sendVerifCodeToEmail(this.$axios, config, this.email.toLowerCase(), params);

      if (result.status === 200) {
        this.countDown = 60;
        this.countDownTimer();
        this.codeSent = true;
        const payload = {
          emailVerifyCount: this.$store.state.signupForm.emailVerifyCount + 1
        };
        this.$store.commit("editSignupForm", payload);
      } else if (result.status === 400) {
        this.messageBox.content = this.$t("errorMsg.signup.emailExists");
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
      }
      this.loading = false;
    },
    async checkVerificationCode() {
      const result = await checkEmailVerificationCode(this.$axios, config, this.email.toLowerCase(), this.code);

      if (result.status === 200) {
        if (result.data.isMatch) {
          return true;
        } else {
          this.messageBox.content = this.$t("errorMsg.signup.emailVerifyWrong");
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
          return false;
        }
      } else {
        this.messageBox.content = this.$t(result.data.message);
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
        return false;
      }
    },
    handleEnter() {
      if (!this.codeSent) {
        this.triggerSendCode();
      } else {
        this.submit();
      }
    }
  },
  computed: {
    codeCorrect() {
      return this.$store.state.signupForm.emailVerifyCorrect;
    },
    allowSendCode() {
      return !this.$v.$invalid && this.countDown === 0;
    },
    allowSubmit() {
      if (this.codeCorrect) {
        return true;
      } else {
        return !this.$v.$invalid && this.codeSent && this.code.length === 6;
      }
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push(this.$t("errorMsg.signup.emailWrong"));
      !this.$v.email.required && errors.push(this.$t("errorMsg.signup.emailEmpty"));
      return errors;
    }
  }
};
</script>

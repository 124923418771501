<template>
  <div class="container">
    <MessageBox
      v-model="messageBox.state"
      :message-content="messageBox.content"
      :message-class="messageBox.cssClass"
      :destination="messageBox.destination"
    />
    <form @keyup.enter="handleEnter">
      <h1>{{ $t("signMobileHeading") }}</h1>
      <VuePhoneNumberInput
        v-model="mobileNumber"
        :preferred-countries="['CN', 'HK']"
        class="phoneInput"
        default-country-code="HK"
        :translations="phoneInputTranslations"
        required
        @update="updatePhone"
        valid-color="green"
        size="lg"
        data-cy="mobileNumber"
        v-if="!codeSent"
        clearable
      />

      <div v-if="!codeSent && countDown > 0">
        <p>
          {{ this.$t("signWait", { countDown: this.countDown }) }}
        </p>
      </div>
      <div v-if="codeSent && !codeCorrect">
        <p>
          {{ this.$t("signMobileVerifyText", { mobileNumber: tel }) }}
        </p>
        <sms-code @onCompleted="codeCompleted" @keydown.enter="submit"></sms-code>
        <p v-if="countDown > 0">
          {{ this.$t("signWait", { countDown: this.countDown }) }}
        </p>
        <p v-else>
          <a @click="sendPhoneCode"> {{ this.$t("signResend") }} </a>
        </p>
      </div>
      <div v-if="codeCorrect">
        <v-alert type="success" color="secondary" outlined>{{ this.$t("signVerified") }}</v-alert>
      </div>
    </form>
    <div class="buttonGroup">
      <v-btn rounded @click="back" depressed
        ><p>{{ this.$t("signBack") }}</p></v-btn
      >
      <v-btn
        depressed
        rounded
        color="primary"
        :disabled="!allowSendCode"
        @click="sendPhoneCode"
        :key="allowSendCode"
        v-if="!codeSent"
        data-cy="sendCode"
        ><p>{{ this.$t("signSend") }}</p></v-btn
      >
      <v-btn
        rounded
        color="primary"
        @click="submit"
        :disabled="!allowSubmit"
        :key="allowSubmit"
        depressed
        data-cy="next"
        :loading="loading"
        v-else
        ><p>{{ this.$t("signNext") }}</p></v-btn
      >
    </div>
  </div>
</template>

<script>
import smsCode from "./smsCode";
import { sendVerifCodeToPhone, checkPhoneVerificationCode } from "@/services/authService";
import config from "@/config.js";
import MessageBox from "@/components/Common/MessageBox";
import VuePhoneNumberInput from "vue-phone-number-input";

export default {
  data: () => ({
    code: "",
    countDown: 0,
    messageBox: {
      state: false,
      content: "",
      cssClass: "error",
      destination: ""
    },
    mobileNumber: "",
    tel: "",
    phoneValid: false,
    codeSent: false,
    loading: false
  }),
  components: {
    smsCode,
    MessageBox,
    VuePhoneNumberInput
  },
  mounted() {
    if (this.$store.state.signupForm.mobileNumber !== "") {
      this.codeSent = true;
    }
    this.mobileNumber = this.$store.state.signupForm.mobileNumber;
  },
  methods: {
    async submit() {
      if (this.codeCorrect) {
        this.$emit("complete");
      } else {
        this.loading = true;
        const verificationResult = await this.checkVerificationCode();

        if (verificationResult === "match") {
          const payload = {
            tel: this.tel,
            mobileNumber: this.mobileNumber,
            phoneVerificationCode: this.code,
            phoneVerifyCorrect: true
          };
          this.$store.commit("editSignupForm", payload);
          this.$emit("complete");
        } else {
          this.messageBox.state = true;
        }
        this.loading = false;
      }
    },
    back() {
      if (this.codeSent) {
        this.codeSent = !this.codeSent;
      } else {
        this.$emit("back");
      }
    },
    codeCompleted(code) {
      this.code = code;
      this.submit();
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    async sendPhoneCode() {
      if (this.countDown === 0) {
        let language;
        switch (this.$i18n.locale) {
          case "en":
            language = 0;
            break;
          case "zhHant":
            language = 1;
            break;
          case "zhHans":
            language = 2;
            break;
          default:
            language = 0;
        }
        const params = {
          userid: this.$store.state.signupForm.userid,
          language: language
        };
        const result = await sendVerifCodeToPhone(this.$axios, config, this.tel, params);
        if (result.status === 200) {
          this.countDown = 60;
          this.countDownTimer();
          this.codeSent = true;
          const payload = {
            phoneVerifyCount: this.$store.state.signupForm.phoneVerifyCount + 1
          };
          this.$store.commit("editSignupForm", payload);
        } else if (result.status === 400) {
          this.messageBox.content = this.$t("errorMsg.signup.phoneExists");
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        } else {
          this.messageBox.content = this.$t(result.data.message);
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        }
      } else {
        if (!this.messageBox.state) {
          this.messageBox.content = this.$t("signWait", {
            countDown: this.countDown
          });
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";
        }
      }
    },
    updatePhone(e) {
      this.phoneValid = e.isValid;
      this.tel = e.formatInternational;
      this.codeSent = false;
      if (this.codeCorrect) {
        const payload = {
          phoneVerifyCorrect: false,
          phoneVerificationCode: ""
        };
        this.$store.commit("editSignupForm", payload);
      }
      this.code = "";
    },
    async checkVerificationCode() {
      const result = await checkPhoneVerificationCode(this.$axios, config, this.tel, this.code);

      if (result.status === 200) {
        if (result.data.isMatch) {
          return "match";
        } else {
          this.messageBox.content = this.$t("errorMsg.signup.phoneVerifyWrong");
          this.messageBox.cssClass = "error";
          this.messageBox.state = true;
          this.messageBox.destination = "";

          return "notMatch";
        }
      } else if (result.status === 500) {
        this.messageBox.content = this.$t("dialogMsg.myCollection.blockchainFail");
        this.messageBox.state = true;
        this.messageBox.destination = "";
        this.$store.commit("showProgressLine", false);
      } else {
        this.messageBox.content = `An error occured. Error code: ${result.status}. Error message: ${result.message}.`;
        this.messageBox.cssClass = "error";
        this.messageBox.state = true;
        this.messageBox.destination = "";
        return "notMatch";
      }
    },
    handleEnter() {
      if (!this.codeSent) {
        this.sendPhoneCode();
      } else if (this.allowSendCode) {
        this.submit();
      }
    }
  },
  computed: {
    codeCorrect() {
      return this.$store.state.signupForm.phoneVerifyCorrect;
    },
    allowSendCode() {
      return this.phoneValid && this.countDown === 0;
    },
    allowSubmit() {
      if (this.codeCorrect) {
        return true;
      } else {
        return this.phoneValid && this.codeSent && this.code.length === 6;
      }
    },
    phoneInputTranslations() {
      return {
        countrySelectorLabel: this.$t("signMobileCountry"),
        phoneNumberLabel: this.$t("signMobileBox")
      };
    }
  }
};
</script>

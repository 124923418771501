<template>
  <div class="container">
    <form>
      <h1>{{ $t("signCompleteHeading") }}</h1>
      <p v-html="$t('signCompleteText')"></p>
    </form>
    <div class="buttonGroup">
      <v-btn rounded color="primary" data-cy="goToLogin" @click="goToLogin" x-large depressed class="mr-2"
        ><p v-html="$t('login')"></p
      ></v-btn>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    email() {
      return this.$store.state.signupForm.email;
    }
  },
  methods: {
    goToLogin() {
      this.$router.push("/login").catch(() => {});
      this.$store.commit("signupReset");
    }
  }
};
</script>

<template>
  <div class="signupStepper" style="width: 100%; height: 100%">
    <Userid v-if="progress === 0" @complete="nextStep" @back="prevStep" />
    <Mobile v-else-if="progress === 1" @complete="nextStep" @back="prevStep" />
    <EmailVerification v-else-if="progress === 2" @complete="nextStep" @back="prevStep" />
    <Password v-else-if="progress === 3" @complete="nextStep" @back="prevStep" />
    <await-login v-else-if="progress === 4" @complete="nextStep" @back="prevStep" />
    <Inbox v-else-if="progress === 5" @complete="nextStep" @back="prevStep" />
  </div>
</template>

<script>
import Userid from "@/components/Signup/Userid";
import Mobile from "@/components/Signup/Mobile";
import EmailVerification from "@/components/Signup/EmailVerification";
import Password from "@/components/Signup/Password";
import Inbox from "@/components/Signup/Inbox";
import AwaitLogin from "./AwaitLogin.vue";

export default {
  components: {
    Userid,
    Mobile,
    EmailVerification,
    Password,
    Inbox,
    AwaitLogin
  },
  methods: {
    nextStep() {
      this.$store.commit("signupNextStep");
    },

    prevStep() {
      this.$store.commit("signupPrevStep");
    }
  },
  computed: {
    progress() {
      return this.$store.state.signupProgress;
    }
  }
};
</script>
